import React from 'react';
import Footer from './Footer';
import { StyledMain } from './styled';

const LayoutComp = (props: any) => {
	return (
		<React.Fragment>
			<StyledMain>{props.children}</StyledMain>
			<Footer />
		</React.Fragment>
	);
};

export default LayoutComp;
