import React from 'react';
import styled from 'styled-components';
import { Card } from '../../theme/components';
import { ImageContainer } from '../Home/styled';
import tutorial_video from '../../images/tutorial_video.png';
import { Row } from 'antd';

export const Hero = styled.div`
  width: 100%;
  min-height: 500px;
  position: relative;
  background: rgb(255,42,76);
	background: linear-gradient(180deg, rgba(238,44,80,1) 0%, rgba(228,32,96,1) 100%);
`

export const HeroContents = styled.div`
  min-height: calc(500px - 150px);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
	@media (max-width: 600px) {
		padding: 0 24px;
  }
`

export const HeroBadge = styled.div`
	height: 32px;
	background-color: #F66D8C;
	color: #fff;
	padding: 8px 24px;
	border-radius: 6px;
	font-size: 15px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 12px;
`

export const HeroTitle = styled.h2`
  color: white !important;
  text-align: center;
  font-size: 40px;
`;

export const HeroDescription = styled.p`
  color: white !important;
  text-align: center;
  font-size: 15px;
  max-width: 500px;
  margin-bottom: 36px;
`

export const DownIcon = styled.img`
  height: 42px;
  width: 42px;
  margin-bottom: 36px;
`;

export const ImageContainerRounded = styled(ImageContainer)`
	border-radius: 12px;
`;

export const GreyPlaceholder = styled.div`
  background-color: #EBEBEB;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  width: 100%;
  @media (max-width: 600px) {
		margin-bottom: 24px;
  }
`

export const TutorialCard = styled.div`
  border-radius: 12px;
	box-shadow: 8px 8px 16px rgba(0,0,0, 0.2);
  background-image: url(${tutorial_video});
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 560px;
  padding: 0;
  height: 290px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
`;

export const TutorialCardOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  border-radius: 12px;
  z-Index: 0;
`

export const TutorialHeader = styled.h2`
  color: white !important;
  z-Index: 1;
  font-size: 40px;
  text-align: center;
`;

export const HowItWorksHeader = styled.h2`
  font-size: 24px;
`

export const HowItWorksRow = styled(Row)`
  margin-bottom: 64px;
`;

export const WhiteHeader = styled.h2`
  color: white !important;
`;

export const PaddedText = styled.p`
  padding-top: 56px;
  @media (max-width: 600px) {
		padding-top: 0;
  }
`