import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import InstagramIcon from '../../icons/instagram.svg'
import { Row, Col } from 'antd'

export const StyledFooter = styled.footer`
  background-color: #EDEDED;
  min-height: 400px;
`;

export const FooterContents = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 48px 24px 24px;
`

export const FooterCol = styled(Col)`
  margin-bottom: 24px;
`

export const FooterTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 18px;
`

export const FooterText = styled.p`
  font-size: 16px;
  margin-bottom: 18px;
`

export const LinkRow = styled(Row)`
  margin-bottom: 16px;
`

export const StyledLink = styled(Link)`
	color: #606977;
	font-size: 16px;
	font-weight: 600;
`

export const StyledExtLink = styled.a`
	color: #606977;
	font-size: 14px;
	font-weight: 600;
`;

export const StyledInstagramIcon = styled(InstagramIcon)`
  height: 32px;
  width: 32px;
  & g {
    fill: #FF2A4C;
  }
`

export const StyledExtLinkFooter = styled(StyledExtLink)`
  font-weight: 400;
`;

export const FooterCopyRight = styled.p`
  color: #A1ADC1;
  font-size: 13px;
  margin-bottom: 0px;
`
