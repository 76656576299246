import React from 'react';
import title from '../../images/title.svg';
import { FooterTitle, StyledFooter, FooterText, FooterCol, StyledExtLinkFooter, StyledInstagramIcon, StyledExtLink, StyledLink, FooterContents, LinkRow, FooterCopyRight } from './styled';
import { Row, Col } from 'antd'

const Footer = () => {
	return (
		<StyledFooter>
			<FooterContents>
				<Row style={{ marginBottom: 36 }}>
					<Col span={24}>
						<img src={title} alt='Logo-DeReader' style={{ marginBottom: 0, maxWidth: 150 }} />
					</Col>
				</Row>
				<Row type="flex" align="top" justify="space-between" gutter={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}>
					<FooterCol xl={7} lg={7} md={7} sm={24} xs={24}>
						<FooterTitle>
							About Us
					</FooterTitle>
						<FooterText>
							We are pleased to introduce to you the only date
							management asset tool you will ever need. We
							have worked hard to refine our product and is
							applicable to many other products.
					</FooterText>
						<StyledExtLinkFooter
							href='https://www.instagram.com/dereader_1/'
							target='_blank'
							rel='noopener noreferrer'
							className='navbar-item'
						>
							<StyledInstagramIcon />
						</StyledExtLinkFooter>
					</FooterCol>
					<FooterCol xl={7} lg={7} md={7} sm={24} xs={24}>
						<Row>
							<FooterTitle>
								Quicklinks
						</FooterTitle>
						</Row>
						<Row gutter={{ xxl: 8, xl: 8, lg: 8, md: 8, sm: 4, xs: 4 }}>
							<Col span={12}>
								<LinkRow>
									<StyledLink to={'/about'}>
										About
							</StyledLink>
								</LinkRow>
								<LinkRow>
									<StyledLink to={'/how-it-works'}>
										How it works
							</StyledLink>
								</LinkRow>
								<LinkRow>
									<StyledLink to={'/pricing'}>
										Pricing
							</StyledLink>
								</LinkRow>
								<LinkRow>
									<StyledLink to={'/connect'}>
										Connect
							</StyledLink>
								</LinkRow>
							</Col>
							<Col span={12}>
								<LinkRow>
									<StyledLink to={'/faq'}>
										Help
							</StyledLink>
								</LinkRow>
								<LinkRow>
									<StyledLink to={'/privacy-policy'}>
										Privacy Policy
							</StyledLink>
								</LinkRow>
								<LinkRow>
									<StyledLink to={'/terms-of-service'}>
										Terms of Service
							</StyledLink>
								</LinkRow>
							</Col>
						</Row>
					</FooterCol>
					<FooterCol xl={7} lg={7} md={7} sm={24} xs={24}>
						<Row>
							<FooterTitle>
								Location
						</FooterTitle>
						</Row>
						<Row style={{ marginBottom: 18 }}>
							<FooterText>
								Calgary, Alberta
						</FooterText>
						</Row>
						<Row>
							<FooterTitle>
								Contact Information
						</FooterTitle>
						</Row>
						<Row>
							<FooterText>
								<StyledExtLink style={{ textDecoration: "underline" }} href="tel:+14035750027" target="_blank" rel="noreferrer noopener">+1 403-575-0027</StyledExtLink> | <StyledExtLink style={{ textDecoration: "underline" }} href="mailto:support@dereader.ca" target="_blank" rel="noreferrer noopener">support@dereader.ca</StyledExtLink>
							</FooterText>
						</Row>
					</FooterCol>
				</Row>
				<Row type="flex" align="middle" justify="center">
					<FooterCopyRight>
						Copyright © 2021 DeReader App • All Rights Reserved
			</FooterCopyRight>
				</Row>
			</FooterContents>
		</StyledFooter>
	);
};

export default Footer;
