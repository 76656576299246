import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import LayoutComp from './LayoutComp';

const Layout = ({ path, children }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={(data) => (
			<React.Fragment>
				<Helmet>
					<html lang='en' />
				</Helmet>
				<LayoutComp>{children}</LayoutComp>
			</React.Fragment>
		)}
	/>
);
export default Layout;
